import PropTypes from 'prop-types'
import cns from 'classnames'
import { useRouter } from 'next/router'

import useLanguageUrl from '@utils/useLanguageUrl'
import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'
import { useABTest } from '@utils/useABTest'

import styles from './cta.module.scss'

export default function Cta({ isSegmentExperiment, pageId, isFree = false }) {
  const t = useFormatMessage()
  const { locale } = useRouter()
  const langUrl = useLanguageUrl()
  const { isABTestForPathname2 } = useABTest()
  const redirectUrl = isSegmentExperiment ? `/${locale}/segment` : langUrl

  const handleRegistrationButtonClick = () => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'footer',
    })
  }

  const buttonText = isFree ? 'cta.home.buttonFree' : 'cta.home.button'

  return (
    <div className={styles.cta}>
      <div className={cns(styles.cta__wrapper, `wrapper`, `wrapper--extended`)}>
        <div className={styles.cta__content}>
          <div className={styles.cta__box}>
            <div className={styles.cta__title}>{t(`cta.home.title`)}</div>

            <p className={styles.cta__text}>
              {t(isABTestForPathname2 ? 'cta.home.text.new' : 'cta.home.text')}
            </p>
            <a
              className={cns(
                styles.cta__button,
                locale === 'br' && styles.cta__button_brazil,
                `button`,
                `button--primary`
              )}
              href={redirectUrl}
              onClick={handleRegistrationButtonClick}
              rel="nofollow"
            >
              {t(isABTestForPathname2 ? 'try' : buttonText)}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Cta.propTypes = {
  isSegmentExperiment: PropTypes.bool,
  pageId: PropTypes.string,
}
